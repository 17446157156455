const Regulated = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 9.45312C14.1841 9.45312 9.45312 14.1841 9.45312 20C9.45312 25.8159 14.1841 30.5469 20 30.5469C25.8159 30.5469 30.5469 25.8159 30.5469 20C30.5469 14.1841 25.8159 9.45312 20 9.45312ZM25.1006 18.9002L19.6566 24.3442C19.4277 24.5731 19.1279 24.6876 18.8281 24.6876C18.5284 24.6876 18.2284 24.5731 17.9996 24.3442L14.484 20.8286C14.0263 20.3709 14.0263 19.6292 14.484 19.1715C14.9417 18.7137 15.6834 18.7137 16.1411 19.1715L18.8281 21.8585L23.4435 17.2431C23.9012 16.7854 24.6429 16.7854 25.1006 17.2431C25.5584 17.7009 25.5584 18.4424 25.1006 18.9002Z"
        fill="#7FD7A5"
      />
      <path
        opacity="0.4"
        d="M38.1723 16.5496C37.3907 15.9946 37.1527 14.96 37.552 14.0891C38.1368 12.8131 38.067 11.3254 37.3655 10.1089C36.6628 8.89242 35.3304 8.08789 33.9342 7.95742C32.9798 7.86703 32.2222 7.10945 32.1318 6.155C32.0013 4.75883 31.1968 3.42641 29.9791 2.72375C28.7627 2.02336 27.2749 1.90203 26 2.48688C25.1337 2.88625 24.0957 2.61047 23.5395 1.82883C22.7283 0.683203 21.4053 0 20 0C18.5958 0 17.2729 0.683203 16.4615 1.82648C15.9065 2.6093 14.873 2.93656 13.9998 2.53719C12.7238 1.95242 11.2349 2.01992 10.0196 2.72375C8.80313 3.42641 7.99859 4.75883 7.86812 6.155C7.77773 7.10937 7.02008 7.86695 6.0657 7.95734C4.66953 8.08781 3.33711 8.89234 2.63445 10.11C1.93297 11.3254 1.86312 12.8131 2.44789 14.0891C2.84727 14.96 2.57031 15.9946 1.78984 16.5496C0.644297 17.361 0 18.6839 0 20.0893C0 21.4947 0.683203 22.8176 1.82648 23.6278C2.60812 24.184 2.84727 25.2197 2.44789 26.0905C1.86312 27.3654 1.93289 28.852 2.63445 30.0697C3.33711 31.2862 4.66953 32.0907 6.0657 32.2212C7.02016 32.3116 7.77773 33.0691 7.86812 34.0236C7.99859 35.4198 8.80313 36.7522 10.0208 37.4548C11.2373 38.1575 12.725 38.2273 13.9999 37.6414C14.8685 37.2443 15.9054 37.392 16.4604 38.1712C17.2717 39.3168 18.5947 40 20 40C21.4053 40 22.7283 39.3168 23.5385 38.1735C24.0959 37.3919 25.1327 37.242 26.0002 37.6413C27.2739 38.2227 28.7639 38.1563 29.9804 37.4548C31.1969 36.7521 32.0014 35.4197 32.1319 34.0235C32.2223 33.0691 32.9798 32.3115 33.9343 32.2211C35.3305 32.0906 36.6629 31.2861 37.3655 30.0684C38.067 28.852 38.1369 27.3653 37.5521 26.0916C37.1527 25.2196 37.3919 24.1839 38.1712 23.6289C39.3168 22.8175 40 21.4946 40 20.0893C40 18.684 39.3168 17.361 38.1723 16.5496ZM20 32.8906C12.8921 32.8906 7.10938 27.1079 7.10938 20C7.10938 12.8921 12.892 7.10938 20 7.10938C27.108 7.10938 32.8906 12.8921 32.8906 20C32.8906 27.1079 27.1079 32.8906 20 32.8906Z"
        fill="#7FD7A5"
      />
    </svg>
  );
};

export default Regulated;
