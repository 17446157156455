import LandingPage from "pages/LandingPage/LandingPage";
import axios from "axios";
import "./App.css";

function App() {
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;
