const LinkedInIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.6516 0.5C18.2115 0.5 18.7485 0.72242 19.1444 1.11833C19.5403 1.51424 19.7627 2.05121 19.7627 2.61111V17.3889C19.7627 17.9488 19.5403 18.4858 19.1444 18.8817C18.7485 19.2776 18.2115 19.5 17.6516 19.5H2.87381C2.31391 19.5 1.77694 19.2776 1.38103 18.8817C0.985115 18.4858 0.762695 17.9488 0.762695 17.3889V2.61111C0.762695 2.05121 0.985115 1.51424 1.38103 1.11833C1.77694 0.72242 2.31391 0.5 2.87381 0.5H17.6516ZM17.1238 16.8611V11.2667C17.1238 10.354 16.7613 9.47877 16.1159 8.83343C15.4706 8.1881 14.5953 7.82556 13.6827 7.82556C12.7855 7.82556 11.7405 8.37444 11.2338 9.19778V8.02611H8.28881V16.8611H11.2338V11.6572C11.2338 10.8444 11.8883 10.1794 12.701 10.1794C13.093 10.1794 13.4688 10.3351 13.746 10.6123C14.0231 10.8894 14.1788 11.2653 14.1788 11.6572V16.8611H17.1238ZM4.85825 6.36889C5.32857 6.36889 5.77962 6.18206 6.11219 5.84949C6.44475 5.51693 6.63158 5.06587 6.63158 4.59556C6.63158 3.61389 5.83992 2.81167 4.85825 2.81167C4.38513 2.81167 3.9314 2.99961 3.59685 3.33416C3.26231 3.6687 3.07436 4.12244 3.07436 4.59556C3.07436 5.57722 3.87658 6.36889 4.85825 6.36889ZM6.32547 16.8611V8.02611H3.40158V16.8611H6.32547Z"
        fill="#2E3478"
      />
    </svg>
  );
};

export default LinkedInIcon;
