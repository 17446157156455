import * as React from "react";
import { styled } from "@mui/material/styles";
// import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownIcon from "Assets/icons/ArrowDownIcon";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: "1px solid #CFD3E7",
  "&:not(:last-child)": {
    borderBottom: "0px !important",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowDownIcon />} {...props} />
))(({ theme }) => ({
  padding: "10px 0px",
  backgroundColor: "rgba(0, 0, 0, .03)",
  columnGap: "20px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "120%",
    color: "#110E2E",
  },

  [theme.breakpoints.down("md")]: {
    "& .MuiAccordionSummary-content": {
      fontSize: "13px",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "20px 0px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#f6f8fa",
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "140%",
  color: "#82849E",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
}));

export default function CustomizedAccordions(props: any) {
  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === `${props?.data?.key}`}
        onChange={handleChange(`${props?.data?.key}`)}
        sx={{ borderBottom: props?.hideBorder ? "0px" : "" }}
      >
        <AccordionSummary
          aria-controls={`${props?.data?.key}d-content`}
          id={`${props?.data?.key}d-header`}
        >
          {props?.data?.question}
        </AccordionSummary>
        <AccordionDetails>{props?.data?.answer}</AccordionDetails>
      </Accordion>
    </div>
  );
}
