import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordian from "./Accordian";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import RectangleColored from "Assets/images/RectangleColored.png";

import "./FAQs.scss";
import { subscribeNewEmail } from "Services/backendService";
import { useState } from "react";

const FAQs1 = [
  {
    key: "ques1",
    question:
      "What makes this different from traditional margin lending solutions?",
    answer:
      "Financial Institutions offer traditional margin lending by borrowing cash at the interbank rate. In order to generate profits, they charge a margin and additional administrative costs on top of the interbank rate. In contrast, we utilize the digital asset market to access capital by sourcing liquidity from market makers who earn fees from swaps. The result is that we can convert to fiat at substantially lower rates than traditional institutions.",
  },
  {
    key: "ques2",
    question: "Which assets can I use as collateral?",
    answer:
      "For now, we only accept cash as collateral. However, we will offer traditional securities as collateral soon.",
  },
  {
    key: "ques3",
    question: "What are you doing with my assets?",
    answer:
      "Regulated third parties hold your collateral assets in segregated accounts. At no time do we have access to your collateral assets.",
  },
  {
    key: "ques4",
    question: "Where are you regulated?",
    answer:
      "We are setting up launch operations in the USA, where we will be authorized to conduct margin lending transactions through an affiliated broker-dealer that is a member of FINRA and SIPC.",
  },
];

const FAQs2 = [
  {
    key: "ques5",
    question: "Can I lose my funds?",
    answer:
      "We have a claim to liquidate your collateral if you default on your loan as the borrower. We will under no circumstances be able to access your collateral assets in any other scenario, as they are held in a segregated account by a regulated third-party custodian.",
  },
  {
    key: "ques6",
    question: "Who can take out a loan?",
    answer:
      "Our launch product is for Corporations and Financial Institutions looking to unlock cheaper financing by utilizing their balance sheet assets. Treasury teams and Fund Managers responsible for driving cost savings or increasing returns through cheaper debt financing should contact us. We intend to extend our offering to retail trading and investment platforms in the near future.",
  },
  {
    key: "ques7",
    question: "What is the minimum loan term?",
    answer:
      "We accept loan applications for terms no shorter than six months, with the most common loans issued for 12 months or longer.",
  },
];

const FAQs = () => {
  const matches = useMediaQuery("(max-width:1100px)");
  const [email, setEmail] = useState<string>("");
  const [openSuccess, setOpenSucess] = React.useState(false);
  const [openFail, setOpenFail] = useState(false);
  const handleSignUpClick = () => {
    let emailRegex = "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}";
    if (email.match(emailRegex)) {
      setCloseDelay(setOpenSucess);
      setEmail("");
      subscribeNewEmail(email);
    } else {
      setCloseDelay(setOpenFail);
    }
  };

  const handleClose = (event?: any, reason?: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFail(false);
    setOpenSucess(false);
  };

  const setCloseDelay = (setter: any, timeout: number = 5000) => {
    setter(true);
    setTimeout(() => {
      setter(false);
    }, timeout);
  };

  return (
    <div className="faqs-container">
      <div className="faqs-section">
        <div className="section-top-text">
          <h5>FAQs</h5>
          <h3>Frequently Asked Questions</h3>
        </div>

        <div className="faqs-content">
          <div>
            {FAQs1.map((faq, index) => (
              <Accordian
                key={index}
                data={faq}
                hideBorder={
                  matches ? false : index === FAQs1.length - 1 ? true : false
                }
              />
            ))}
          </div>
          <div>
            {FAQs2.map((faq, index) => (
              <Accordian
                key={index}
                data={faq}
                hideBorder={
                  matches ? false : index === FAQs2.length - 1 ? true : false
                }
              />
            ))}
          </div>
        </div>

        <div className="join-our-waitlist">
          <div className="waitlist-div">
            <img src={RectangleColored} alt="RectangleColored" />

            <div className="waitlist-content">
              <h2>Join Our Waitlist</h2>
              <div className="email-text-field">
                <p>Your Email</p>
                <input
                  type="text"
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                />
                <button onClick={handleSignUpClick}>Sign Up</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={AlertPosition}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Email has Subscribed, thanks for your interest.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFail}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={AlertPosition}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Email format is incorrect, please re-enter.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FAQs;

const Alert = React.forwardRef(function Alert(props: any, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertPosition: SnackbarOrigin = {
  vertical: "top",
  horizontal: "center",
};
