const ScrollingIcon = () => {
  return (
    <svg width="23" height="34" viewBox="0 0 23 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 0C5.1588 0 0 4.39531 0 9.79803V23.5123C0 28.915 5.1588 33.3103 11.5 33.3103C17.8412 33.3103 23 28.915 23 23.5123V9.79803C23 4.39531 17.8412 0 11.5 0ZM20.6543 23.5123C20.6543 27.8128 16.5475 31.3118 11.5 31.3118C6.45246 31.3118 2.3457 27.8128 2.3457 23.5123V9.79803C2.3457 5.49751 6.45246 1.99854 11.5 1.99854C16.5475 1.99854 20.6543 5.49751 20.6543 9.79803V23.5123Z"
        fill="white"
      />
      <path
        d="M11.5002 7.93115C10.843 7.93115 10.3105 8.46881 10.3105 9.13232V14.661C10.3105 15.3242 10.843 15.8622 11.5002 15.8622C12.1571 15.8622 12.6899 15.3242 12.6899 14.661V9.13232C12.6899 8.46881 12.1574 7.93115 11.5002 7.93115Z"
        fill="white"
      />
    </svg>
  );
};

export default ScrollingIcon;
