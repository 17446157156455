const AppLogo = () => {
  return (
    <svg
      width="150"
      height="37"
      viewBox="0 0 150 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5417_6554)">
        <path
          d="M48.4467 12.1041V16.3611H54.4453V19.6437H48.4467V24.8763H54.6796V28.1589H44.6763V8.82031H54.6796V12.1041H48.4467Z"
          fill="#2E3478"
        />
        <path
          d="M70.1184 13.9503C69.6021 13.3177 68.9533 12.8061 68.2177 12.4517C67.4822 12.0972 66.6778 11.9085 65.8613 11.8989C62.2453 11.8989 60.168 15.2322 60.168 18.5667C60.168 21.8245 62.3219 25.0812 65.8354 25.0812C67.452 25.0812 69.0674 24.2352 70.1161 23.0299V27.5179C68.7057 28.1847 67.2999 28.6725 65.7307 28.6725C60.293 28.6725 56.2681 23.9784 56.2681 18.6467C56.2681 13.1584 60.141 8.31104 65.8084 8.31104C67.3164 8.31907 68.7982 8.70647 70.1173 9.43754L70.1184 13.9503Z"
          fill="#2E3478"
        />
        <path
          d="M85.379 24.8763V28.1589H71.3496L79.8648 12.1041H72.6552V8.82031H85.8353L77.3505 24.8763H85.379Z"
          fill="#2E3478"
        />
        <path
          d="M106.948 18.4903C106.948 23.9279 102.357 28.4159 96.9223 28.4159C91.4881 28.4159 86.8965 23.9279 86.8965 18.4903C86.8965 13.0527 91.4611 8.5647 96.9223 8.5647C102.357 8.5647 106.948 13.0527 106.948 18.4903ZM88.1277 18.4903C88.1277 23.261 92.1032 27.2612 96.9257 27.2612C101.721 27.2612 105.723 23.2351 105.723 18.4903C105.723 13.7196 101.721 9.71823 96.9257 9.71823C92.0975 9.71823 88.1277 13.7196 88.1277 18.4903Z"
          fill="#A4ACD1"
        />
        <path
          d="M109.948 8.82031H113.44C116.257 8.82031 118.494 9.28218 120.622 11.2569C122.65 13.1551 123.699 15.746 123.699 18.5161C123.71 19.836 123.451 21.1441 122.94 22.3609C122.428 23.5777 121.674 24.6777 120.724 25.5939C118.596 27.6464 116.313 28.1589 113.44 28.1589H109.948V8.82031ZM113.411 27.0054C115.95 27.0054 118.053 26.4917 119.9 24.6713C120.732 23.8408 121.389 22.8508 121.83 21.7607C122.271 20.6705 122.487 19.5026 122.466 18.3268C122.445 17.151 122.187 15.9916 121.707 14.9179C121.228 13.8442 120.536 12.8783 119.674 12.0781C117.828 10.4109 115.827 9.97497 113.417 9.97497H111.185V27.0054H113.411Z"
          fill="#A4ACD1"
        />
        <path
          d="M127.901 9.97385V17.001H135.698V18.1556H127.901V27.0043H135.899V28.1578H126.662V8.82031H135.899V9.97385H127.901Z"
          fill="#A4ACD1"
        />
        <path
          d="M138.108 8.82031H139.467L143.904 17.1305L148.341 8.82031H149.701L144.648 18.2829L149.957 28.1567H148.621L143.902 19.4105L139.234 28.1567H137.9L143.184 18.2829L138.108 8.82031Z"
          fill="#A4ACD1"
        />
        <path
          d="M20.6808 34.0238C17.8318 34.0249 15.0258 33.3292 12.5072 31.9974C9.98871 30.6656 7.83411 28.738 6.23122 26.3827C7.45911 26.7364 9.04747 26.8333 10.8724 26.6824C13.9872 30.1125 18.572 31.6739 22.8493 30.3074C24.2083 29.8723 25.4636 29.163 26.5375 28.2234C25.6908 28.8082 24.7618 29.2638 23.781 29.5752C19.7436 30.865 15.4325 29.5459 12.3425 26.5089C15.2973 26.0819 18.7365 25.0816 22.2557 23.5315C29.2163 20.4607 34.3802 16.1541 35.4808 12.8467C34.0163 15.8961 29.3245 19.6462 23.1422 22.3735C18.8131 24.2885 14.6045 25.317 11.3591 25.4297C10.2008 24.0135 9.32157 22.3904 8.7681 20.6466C8.42151 19.5634 8.2015 18.4437 8.11248 17.3099C9.80223 15.646 12.1589 13.9461 14.9672 12.4265C12.2873 13.7637 9.94191 15.2698 8.08206 16.7939C7.91872 12.8264 9.43386 9.11347 12.1375 6.7512C8.83456 9.04137 6.95556 13.1632 7.15495 17.596C5.62291 18.9861 4.51669 20.3559 3.94443 21.6131C2.67027 17.3987 3.03084 12.8589 4.95428 8.8985C6.87771 4.93805 10.2228 1.84775 14.3229 0.243408C11.2841 0.983344 8.48307 2.48323 6.18277 4.60232C3.88248 6.7214 2.1583 9.39019 1.17205 12.3582C0.185809 15.3262 -0.0301653 18.4962 0.544402 21.5706C1.11897 24.6449 2.46524 27.5229 4.45686 29.9344C6.44848 32.3459 9.02015 34.2118 11.9305 35.3571C14.8409 36.5024 17.9945 36.8894 21.0955 36.4819C24.1964 36.0744 27.143 34.8857 29.6586 33.0273C32.1742 31.169 34.1765 28.702 35.4774 25.8578C33.9034 28.3596 31.7202 30.421 29.1324 31.8492C26.5445 33.2774 23.6366 34.0256 20.6808 34.0238ZM7.19776 18.2854C7.44332 20.8524 8.35722 23.3102 9.84841 25.4139C7.61457 25.2697 6.02958 24.5781 5.47422 23.3186C4.87942 21.9657 5.56434 20.1779 7.19776 18.2854Z"
          fill="#2E3478"
        />
      </g>
      <defs>
        <clipPath id="clip0_5417_6554">
          <rect width="150" height="36.8812" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppLogo;
