const SecureFramework = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5483_6562)">
        <path
          d="M34.0809 8.13965C33.6056 6.23661 32.5432 4.51075 31.0379 3.21801C29.3132 1.737 27.113 0.92145 24.8423 0.92145C23.1387 0.92145 21.507 1.36075 20.0667 2.20004C18.5415 0.8052 16.5416 0.0216064 14.4402 0.0216064C9.93297 0.0216064 6.24906 3.61465 6.10203 8.08669C4.54383 8.52223 3.13297 9.40879 2.05445 10.6454C0.729688 12.1647 0 14.1101 0 16.1235C0 20.7237 3.7425 24.4662 8.34273 24.4662H31.6573C36.2575 24.4662 40 20.7237 40 16.1235C40 12.4351 37.5537 9.19497 34.0809 8.13965Z"
          fill="#7FD7A5"
        />
        <path
          opacity="0.4"
          d="M12.5781 26.8102V29.0407H8.42992C7.91836 27.2395 6.25984 25.9159 4.29688 25.9159C1.92758 25.9159 0 27.8435 0 30.2128C0 32.5821 1.92758 34.5096 4.29688 34.5096C6.26 34.5096 7.91859 33.1858 8.43008 31.3844H13.75C14.3969 31.3844 14.9219 30.8602 14.9219 30.2125V26.8102H12.5781Z"
          fill="#7FD7A5"
        />
        <path
          opacity="0.4"
          d="M21.1719 31.5484V26.8102H18.8281V31.5484C17.0268 32.0599 15.7031 33.7185 15.7031 35.6815C15.7031 38.0508 17.6307 39.9784 20 39.9784C22.3693 39.9784 24.2969 38.0508 24.2969 35.6815C24.2969 33.7185 22.9732 32.0599 21.1719 31.5484Z"
          fill="#7FD7A5"
        />
        <path
          opacity="0.4"
          d="M35.7031 25.9159C33.7402 25.9159 32.0816 27.2395 31.5701 29.0407H27.4219V26.8102H25.0781V30.2125C25.0781 30.8602 25.6031 31.3844 26.25 31.3844H31.5699C32.0814 33.1858 33.74 34.5096 35.7031 34.5096C38.0724 34.5096 40 32.5821 40 30.2128C40 27.8435 38.0724 25.9159 35.7031 25.9159Z"
          fill="#7FD7A5"
        />
      </g>
      <defs>
        <clipPath id="clip0_5483_6562">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SecureFramework;
