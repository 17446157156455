import Regulated from "Assets/images/Regulated";
import DiverseCollateral from "Assets/images/DiverseCollateral";
import TrustedPartners from "Assets/images/TrustedPartners";
import SecureFramework from "Assets/images/SecureFramework";
import RectangleGrey from "Assets/images/RectangleGrey.png";

import "./WhyEczodex.scss";

const cardsData = [
  {
    image: <Regulated />,
    title: "Regulated",
    text: "Under our affiliated broker-dealer license, we can offer a regulated margin lending service.",
  },
  {
    image: <DiverseCollateral />,
    title: "Diverse Collateral",
    text: "Choose from a range of collateral assets, such as cash, stocks and fixed-income instruments.",
  },
  {
    image: <TrustedPartners />,
    title: "Trusted Partners",
    text: "Our platform connects traditional institutional partners who retain custody of collateral assets to market leaders in the digital asset ecosystem.",
  },
  {
    image: <SecureFramework />,
    title: "Secure Framework",
    text: "Our blockchain integrations follow best practice security frameworks that ensure the safe conversion of digital assets into cash.",
  },
];

const WhyEczodex = () => {
  return (
    <div className="why-eczodex-container">
      <div className="main-content-div">
        <div className="section-top-text">
          <h5>Why Eczodex</h5>
          <h3>A regulated, secure platform providing access to the benefits of the new economy</h3>
        </div>

        <div className="cards-container">
          {cardsData.map((card, index) => (
            <div className="card-div" key={index}>
              {/* <img src={card?.image} alt={"CardImage"} /> */}
              {card?.image}
              <h5>{card?.title}</h5>
              <p>{card?.text}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="rectangle-div">
        <img src={RectangleGrey} alt="RectangleGrey" />
      </div>
    </div>
  );
};

export default WhyEczodex;
