const TwitterIcon = () => {
  return (
    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.7628 2.33682C19.9816 2.67664 19.1556 2.90221 18.3101 3.00658C19.2023 2.47842 19.8696 1.64119 20.1854 0.653694C19.351 1.14846 18.4382 1.49685 17.4863 1.6838C16.9026 1.06071 16.1451 0.627542 15.312 0.440572C14.479 0.253602 13.609 0.321468 12.815 0.635355C12.0211 0.949242 11.3399 1.49464 10.8599 2.2007C10.3799 2.90676 10.1233 3.74085 10.1235 4.5946C10.1201 4.92076 10.1532 5.24627 10.222 5.5651C8.52855 5.48204 6.87171 5.04251 5.35979 4.27523C3.84786 3.50796 2.5149 2.43022 1.44804 1.11248C0.900234 2.04891 0.730559 3.15913 0.973661 4.21643C1.21676 5.27374 1.8543 6.19836 2.75609 6.80149C2.08172 6.78482 1.42126 6.60609 0.830511 6.28041V6.32729C0.831698 7.31023 1.17146 8.26276 1.79261 9.02456C2.41377 9.78637 3.27839 10.3109 4.24097 10.51C3.87687 10.6059 3.50164 10.653 3.12513 10.65C2.85538 10.6545 2.58593 10.63 2.32141 10.577C2.59638 11.4212 3.12612 12.1596 3.83781 12.6906C4.5495 13.2215 5.40819 13.519 6.29581 13.542C4.78909 14.7204 2.93085 15.3597 1.01805 15.3578C0.677802 15.3601 0.337757 15.3406 0 15.2995C1.94647 16.5538 4.21465 17.2172 6.53023 17.2097C14.3632 17.2097 18.6457 10.721 18.6457 5.09693C18.6457 4.90873 18.639 4.72722 18.6302 4.54705C19.4695 3.94606 20.1922 3.19706 20.7628 2.33682Z"
        fill="#2E3478"
      />
    </svg>
  );
};

export default TwitterIcon;
