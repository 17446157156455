import AppLogo from "Assets/images/AppLogo";
import TwitterIcon from "Assets/icons/TwitterIcon";
import LinkedInIcon from "Assets/icons/LinkedInIcon";
import MailIcon from "Assets/icons/MailIcon";

import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="col-1">
        {/* <img src={AppLogo} alt="AppLogo" /> */}
        <AppLogo />
        {/* <br /> */}
        {/* <small>Ⓒ All Rights reserved 2021 seekers.</small> */}
      </div>

      <div className="col-2">
        <h6>Get in Touch</h6>
        {/* <p>Blind would equal while oh mr do style. Lain led and fact none. </p> */}
        <div className="social-icons-div">
          <div
            onClick={() => window.open("https://twitter.com/eczodex", "_blank")}
            className="mouse-cursor-pointer"
          >
            <TwitterIcon />
          </div>
          <div
            onClick={() =>
              window.open("https://www.linkedin.com/company/eczodex/", "_blank")
            }
            className="mouse-cursor-pointer"
          >
            <LinkedInIcon />
          </div>
          <a href="mailto:info@eczodex.com">
            <MailIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
