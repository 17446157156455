import React from "react";

import CambridgeUniversity from "Assets/images/CambridgeUniversity";
import Techstars from "Assets/images/Techstars";
import CloudPlatforms from "Assets/images/CloudPlatforms.png";
import RectangleWhite from "Assets/images/RectangleWhite.png";

import "./About.scss";

const About = () => {
  return (
    <div className="about-container">
      <div className="columns-container">
        <div className="col-1">
          <h6>ABOUT</h6>
          <h2>
            <span>Cost Competitive Debt Financing</span> <br /> sourced from the new financial
            economy
          </h2>
          <p>
            Our innovative debt financing solution allows Corporations and Financial Institutions to
            access the capital they need while leveraging their traditional assets. We offer loans
            at highly competitive rates, creating the perfect solution for those seeking to take
            advantage of their idle assets.
          </p>

          <div className="companies-div">
            <div>
              <p>FOUNDED AT</p>
              {/* <img src={CambridgeUniversity} alt="CambridgeUniversity" /> */}
              <CambridgeUniversity />
            </div>
            <div>
              <p>BACKED BY TECHSTARS</p>
              {/* <img src={Techstars} alt="Techstars" /> */}
              <Techstars />
            </div>
          </div>
        </div>

        <div className="col-2">
          <img src={CloudPlatforms} alt="" />
        </div>
      </div>
      <div className="rectangle-div">
        <img src={RectangleWhite} alt="RectangleWhite" />
      </div>
    </div>
  );
};

export default About;
