const MailIcon = () => {
  return (
    <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.1933 2.34151L15.1259 7.50274C15.1166 7.51127 15.1092 7.52163 15.1042 7.53315C15.0991 7.54468 15.0965 7.55713 15.0965 7.56972C15.0965 7.5823 15.0991 7.59475 15.1042 7.60628C15.1092 7.61781 15.1166 7.62816 15.1259 7.63669L18.6723 11.4122C18.7294 11.4689 18.7748 11.5365 18.8057 11.6108C18.8367 11.6852 18.8526 11.765 18.8526 11.8455C18.8526 11.9261 18.8367 12.0058 18.8057 12.0802C18.7748 12.1546 18.7294 12.2221 18.6723 12.2789C18.5572 12.3935 18.4014 12.4578 18.239 12.4578C18.0765 12.4578 17.9207 12.3935 17.8056 12.2789L14.2739 8.5161C14.256 8.49875 14.2319 8.48905 14.207 8.48905C14.182 8.48905 14.158 8.49875 14.14 8.5161L13.278 9.39216C12.9256 9.75196 12.5051 10.038 12.041 10.2334C11.5768 10.4289 11.0784 10.53 10.5748 10.5308C10.06 10.5288 9.55068 10.424 9.07683 10.2226C8.60299 10.0213 8.17412 9.72729 7.81537 9.358L6.9862 8.51476C6.96822 8.49741 6.94421 8.48771 6.91923 8.48771C6.89424 8.48771 6.87023 8.49741 6.85225 8.51476L3.31521 12.2782C3.20011 12.3928 3.04429 12.4571 2.88187 12.4571C2.71944 12.4571 2.56363 12.3928 2.44853 12.2782C2.39138 12.2214 2.34603 12.1539 2.31508 12.0795C2.28413 12.0052 2.2682 11.9254 2.2682 11.8448C2.2682 11.7643 2.28413 11.6845 2.31508 11.6102C2.34603 11.5358 2.39138 11.4683 2.44853 11.4115L5.99495 7.63469C6.01142 7.61627 6.02053 7.59242 6.02053 7.56771C6.02053 7.543 6.01142 7.51915 5.99495 7.50073L0.922799 2.34151C0.909778 2.32802 0.892983 2.31876 0.874615 2.31497C0.856246 2.31118 0.837161 2.31302 0.819859 2.32026C0.802556 2.3275 0.787844 2.3398 0.777649 2.35554C0.767455 2.37129 0.762253 2.38974 0.762725 2.40849V12.7363C0.763961 13.1356 0.923129 13.5182 1.20547 13.8005C1.48782 14.0829 1.87041 14.242 2.2697 14.2433H18.8464C19.2457 14.242 19.6283 14.0829 19.9107 13.8005C20.193 13.5182 20.3522 13.1356 20.3534 12.7363V2.40715C20.353 2.3888 20.3472 2.37098 20.3368 2.35586C20.3264 2.34073 20.3118 2.32897 20.2948 2.32201C20.2779 2.31505 20.2592 2.31318 20.2412 2.31665C20.2232 2.32011 20.2065 2.32875 20.1933 2.34151Z"
        fill="#2E3478"
      />
      <path
        d="M10.5581 9.30179C10.9009 9.30329 11.2405 9.23595 11.5568 9.10375C11.8731 8.97156 12.1596 8.77721 12.3993 8.53223L19.7882 1.00873C19.5244 0.79526 19.1952 0.678923 18.8559 0.679204H2.26508C1.92563 0.678376 1.5963 0.794776 1.33276 1.00873L8.72164 8.53223C8.96084 8.77664 9.24659 8.97067 9.56202 9.10284C9.87744 9.23501 10.2161 9.30266 10.5581 9.30179Z"
        fill="#2E3478"
      />
    </svg>
  );
};

export default MailIcon;
