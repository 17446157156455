import ScrollingIcon from "Assets/icons/ScrollingIcon";
import AppLogo from "Assets/images/AppLogo";
import RectangleGrey from "Assets/images/RectangleGrey.png";

import "./Hero.scss";

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <div className="logo-div">
          {/* <img src={AppLogo} alt="AppLogo" /> */}
          <AppLogo />
        </div>

        <div className="main-heading">
          <h1>
            Financial products for
            <br />
            the new economy
          </h1>
          <p>Connecting old and new markets to unleash products fit for the new economy</p>
          <div className="scroll-div">
            <ScrollingIcon /> <br />
            <small>Scroll down for more details</small>
          </div>
        </div>
      </div>
      <div className="rectangle-div">
        <img src={RectangleGrey} alt="RectangleGrey" />
      </div>
    </div>
  );
};

export default Hero;
