const DiverseCollateral = () => {
  return (
    <svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M6.86426 4.8598H33.1359C32.2708 3.59894 31.6945 2.12542 31.5029 0.535034H8.49723C8.30559 2.12542 7.72934 3.59894 6.86426 4.8598Z"
        fill="#7FD7A5"
      />
      <path
        opacity="0.4"
        d="M36.2291 4.8598H40.0001V1.99863C40.0001 1.1916 39.3435 0.535034 38.5365 0.535034H33.8691C34.1418 2.24027 34.9993 3.75285 36.2291 4.8598Z"
        fill="#7FD7A5"
      />
      <path
        opacity="0.4"
        d="M3.77102 4.85976C5.00078 3.7528 5.85828 2.24022 6.13094 0.534912H1.46359C0.656562 0.534912 0 1.19155 0 1.99858V4.85976H3.77102Z"
        fill="#7FD7A5"
      />
      <path
        opacity="0.6"
        d="M4.03125 11.2825C5.12148 10.2021 5.87789 8.78576 6.13094 7.20349H0V11.2825H4.03125Z"
        fill="#7FD7A5"
      />
      <path
        opacity="0.6"
        d="M8.49722 7.20349C8.31792 8.69154 7.80237 10.0776 7.02808 11.2825H32.9721C32.1978 10.0776 31.6821 8.69154 31.5029 7.20349H8.49722Z"
        fill="#7FD7A5"
      />
      <path
        opacity="0.6"
        d="M40.0001 7.20349H33.8691C34.1221 8.78576 34.8786 10.2021 35.9688 11.2825H40.0001V7.20349Z"
        fill="#7FD7A5"
      />
      <path
        d="M0 31.3341V36.0015C0 36.8085 0.656562 37.465 1.46359 37.465H6.13094C5.62867 34.3237 3.14141 31.8364 0 31.3341Z"
        fill="#7FD7A5"
      />
      <path
        d="M6.13094 13.6262H0V19.7572C3.14141 19.2549 5.62867 16.7676 6.13094 13.6262Z"
        fill="#7FD7A5"
      />
      <path
        d="M31.5028 13.6262H8.49719C7.96281 18.0618 4.43563 21.589 0 22.1233V28.9678C4.43563 29.5022 7.96281 33.0293 8.49719 37.465H31.5029C32.0373 33.0293 35.5644 29.5022 40.0001 28.9678V22.1233C35.5644 21.589 32.0372 18.0618 31.5028 13.6262ZM21.3366 24.3881C24.5745 25.4903 24.2885 29.9814 21.1138 31.0708V31.4237C21.0519 32.9786 18.8314 32.9774 18.7701 31.4237V31.196C18.1465 31.1029 17.6011 30.8872 16.8837 30.4179C16.342 30.0637 16.1902 29.3374 16.5445 28.7958C16.8988 28.254 17.625 28.1022 18.1666 28.4565C18.8226 28.8855 19.0505 28.9225 19.9341 28.9158C20.7305 28.9106 21.1927 28.3171 21.285 27.7679C21.3391 27.4464 21.3161 26.8668 20.5554 26.5979C18.4655 25.851 16.2248 25.0118 16.6793 22.3903C16.8981 21.2289 17.6961 20.2998 18.7701 19.9435V19.6676C18.832 18.1128 21.0525 18.114 21.1138 19.6676V19.8718C21.7717 20.05 22.2321 20.3525 22.4252 20.4968C22.9437 20.8843 23.0498 21.6186 22.6623 22.1371C22.2748 22.6555 21.5406 22.7617 21.022 22.3741C20.8316 22.2318 20.3044 21.9259 19.5258 22.1604C19.1144 22.2844 19.006 22.6999 18.9825 22.8243C18.9351 23.0761 18.9961 23.2799 19.0776 23.3438C19.5802 23.7379 20.5016 24.0929 21.3366 24.3881Z"
        fill="#7FD7A5"
      />
      <path
        d="M40.0001 31.3341C36.8587 31.8364 34.3714 34.3236 33.8691 37.465H38.5365C39.3435 37.465 40.0001 36.8085 40.0001 36.0015V31.3341Z"
        fill="#7FD7A5"
      />
      <path
        d="M40.0001 13.6262H33.8691C34.3714 16.7676 36.8587 19.2549 40.0001 19.7572V13.6262Z"
        fill="#7FD7A5"
      />
    </svg>
  );
};

export default DiverseCollateral;
