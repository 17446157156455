const TrustedPartners = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.95312 16.6406H5.625V32.5781H1.95312V16.6406Z" fill="#7FD7A5" />
      <path d="M7.96875 16.6406H11.9531V32.5781H7.96875V16.6406Z" fill="#7FD7A5" />
      <path d="M28.0469 16.6406H32.0312V32.5781H28.0469V16.6406Z" fill="#7FD7A5" />
      <path opacity="0.4" d="M14.2969 16.6406H25.7031V32.5781H14.2969V16.6406Z" fill="#7FD7A5" />
      <path
        d="M0.078125 36.0938V38.8281C0.078125 39.4753 0.602812 40 1.25 40H38.75C39.3972 40 39.9219 39.4753 39.9219 38.8281V36.0938C39.9219 35.4466 39.3972 34.9219 38.75 34.9219H1.25C0.602812 34.9219 0.078125 35.4466 0.078125 36.0938Z"
        fill="#7FD7A5"
      />
      <path
        d="M1.2501 14.2968H38.7501C39.271 14.2968 39.7293 13.9531 39.8751 13.4531C40.021 12.9531 39.8192 12.4168 39.3801 12.1368L20.6301 0.183691C20.2458 -0.0612305 19.7545 -0.0612305 19.3702 0.183691L0.620181 12.1368C0.180962 12.4167 -0.0207563 12.953 0.125103 13.4531C0.270884 13.9531 0.729244 14.2968 1.2501 14.2968ZM18.8337 7.38432C18.9037 6.70963 19.5253 6.21869 20.229 6.35072C20.8658 6.48322 21.2701 7.08377 21.1493 7.72885C21.0172 8.36228 20.4199 8.77057 19.7712 8.64916C19.1935 8.52869 18.7821 8.01486 18.8337 7.38432Z"
        fill="#7FD7A5"
      />
      <path d="M34.375 16.6406H38.0469V32.5781H34.375V16.6406Z" fill="#7FD7A5" />
    </svg>
  );
};

export default TrustedPartners;
