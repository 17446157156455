import Hero from "./components/Hero/Hero";
import About from "./components/About/About";
import WhyEczodex from "./components/WhyEczodex/WhyEczodex";
import FAQs from "./components/FAQs/FAQs";
import Footer from "./components/Footer/Footer";

import "./LandingPage.scss";

const LandingPage = () => {
  return (
    <div>
      <Hero />
      <About />
      <WhyEczodex />
      <FAQs />
      <Footer />
    </div>
  );
};

export default LandingPage;
